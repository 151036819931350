.wrapper {
    background-color: #f6f6f6;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    box-shadow: 1px 1px 18px 0 #afafaf;
    padding: 14px;
    outline: 0;
    border-radius: 4px;
}

.header {
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 65px;
  //background:black;
  //padding: 12px;T
}

.rightSection {
  display: flex;
  align-items: center;
  margin-right: 30px;
}

.bellIcon {
  width: 22px;
  margin-right: 85px;
  cursor: pointer;
}

.themeButton {
  width: 155px;
  height: 37px;
  border: 1px solid var(--border-color-02-opacity);
  border-radius: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 16px;
  margin-right: 70px;

  span {
    color: var(--text-primary-color);
  }

  &:hover {
    cursor: pointer;
    opacity: 0.5;
  }
}

.avatarBlock {
  display: flex;
  flex-direction: row;
  align-items: center;
  //padding: 12px;
}

.userWrapper {
  display: flex;
}

.userInfo {
  display: flex;
  flex-direction: column;
  margin-right: 10px;
  justify-content: center;
}

.userAvatarImg {
  border-radius: 50%;
  overflow: hidden;
  margin-right: 10px;
  width: 43px;
  height: 43px;
}

.loginBtn,
.logoutBtn {
  &:hover {
    cursor: pointer;
    opacity: 0.5;
  }
}

.userName {
  color: var(--base-blue-color);
  font-weight: 700;
}

/* Define styles for the default root window element */
:root {
  --select-height: 52px;
  --app-background: #F4F8FB;
  --background-color-page: #E5E5E5;
  --background-color-primary: #FFFFFF;
  --background-color-secondary: rgba(28, 122, 175, 0.05);
  --grey-additional-color: #ececec;
  --accent-color: #cacaca;
  --text-primary-color: #212835;
  --element-size: 4rem;
  --base-blue-color: #1C7AAF;
  --border-color-02-opacity: rgba(28, 122, 175, 0.2);
  --base-green-color: #10A35C;
  --base-border-color: rgba(33, 40, 53, 0.2);
  --base-border-color-focused: rgba(33, 40, 53, 0.5);
  --error-color: #C83535;
  --dashed-grey-border: 1px dashed #E6E6E6;
  --dashed-blue-border: 1px dashed #1C7AAF;
  --max-document-width: 1317px;
}

/* Define styles for the root window with dark - mode preference */
:root.dark-theme {
  --app-background: #585a5b;
  --background-color-primary: #1e1e1e;
  --base-blue-color: #a2b8c4;
  --border-color-02-opacity: rgba(255, 255, 255, 0.2);
  --background-color-secondary: #2d2d30;
  --accent-color: #3f3f3f;
  --text-primary-color: #ddd;
}

body::-webkit-scrollbar,
body ::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

body::-webkit-scrollbar-thumb,
body ::-webkit-scrollbar-thumb {
  background: var(--base-blue-color);
  border-radius: 2px;
}

body::-webkit-scrollbar-track,
body ::-webkit-scrollbar-track {
  border-radius: 2px;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'Roboto', sans-serif;
}

.App {
  a {
    color: var(--base-blue-color);
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}

.cursor-pointer {
  cursor: pointer;
}

.text-16 {
  font-size: 16px;
}

.position-relative {
  position: relative;
}

.text-14 {
  font-size: 14px;
}

.pointer-events-none {
  pointer-events: none;
  user-select: none;
}

.border-box {
  box-sizing: border-box;
}

body {
  font-weight: 400;
  background-color: var(--app-background);
}

.dashed-grey-color {
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%23C0C0C0' stroke-width='1' stroke-dasharray='18' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
}

.dashed-blue-color {
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%231C7AAF' stroke-width='1' stroke-dasharray='18' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
}

.flex {
  display: flex !important;
}

.text-editor {
  p, ul, pre, blockquote, h1, h2, h3, h4, h5, h6,
  ol, li {
    margin: 0;
    padding: 0;
    counter-reset: list-1 list-2 list-3 list-4 list-5 list-6 list-7 list-8 list-9;
  }

  ol, ul {
    padding-left: 1.5em;
  }

  ol li {
    counter-reset: list-1 list-2 list-3 list-4 list-5 list-6 list-7 list-8 list-9;
    counter-increment: list-0;
  }
}

.space-between {
  justify-content: space-between;
}

.ui.selection.dropdown,
.ui.input input {
  border: 1px solid var(--base-border-color);
  border-radius: 0;
  height: 60px;
  box-sizing: border-box;
}

.ui.labeled.input .ui.label.label {
  display: flex;
  align-items: center;
}

.ui.search.dropdown {
  .search {
    height: 60px;
  }

  .dropdown.icon {
    top: 21px;

    &:before {
      font-size: 18px;
    }
  }
}

.ui.selection.dropdown {
  display: flex;
  align-items: center;

  .dropdown.icon {
    top: 21px;

    &:before {
      font-size: 18px;
    }
  }
}

.logged-in .grecaptcha-badge {
  visibility: hidden;
}

.ph-15 {
  padding-left: 15px;
  padding-right: 15px;
}

button,
input,
optgroup,
select,
textarea {
  color: inherit;
  font: inherit;
  margin: 0;
  background: transparent;
  outline: none;
  border: none;
  border-radius: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.w-100 {
  width: 100%;
}

.page-container {
  //padding: 20px;
  //width: 100%;
  //height: auto;
  flex-grow: 1;
  padding: 0 20px 20px;
  overflow-y: auto;
}

.rotate180 {
  transform: rotate(180deg);
}

.opacity0 {
  opacity: 0 !important;
}

.opacity05 {
  opacity: 0.5 !important;
}

.opacity1 {
  opacity: 1 !important;
}

.mb-18 {
  margin-bottom: 18px;
}

.pa-10 {
  padding: 10px;
}

.flex-1 {
  flex: 1;
}

.flex-2 {
  flex: 2;
}

.mr-18 {
  margin-right: 18px;
}

.mb-12 {
  margin-bottom: 12px;
}

div .ui.toggle.checkbox input:focus:checked ~ .box:before,
div .ui.toggle.checkbox input:focus:checked ~ label:before,
div .ui.toggle.checkbox input:checked ~ .box:before,
div .ui.toggle.checkbox input:checked ~ label:before {
  background-color: var(--base-green-color) !important;
}

.react-datepicker-wrapper,
.react-datepicker__input-container,
.react-datepicker__input-container input {
  height: 100%;
  width: 100%;
}

.react-datepicker .react-datepicker__triangle {
  left: -10px !important;
}

.phone-input .react-tel-input .form-control {
  width: 100%;
  border-radius: 0;
  height: 60px;
}

.rotate180 {
  transform: rotate(180deg);
}

.bg-additional {
  background-color: var(--app-background) !important;
}

.react-datepicker-popper {
  z-index: 2 !important;
}

.MuiFormHelperText-root.Mui-error {
  position: absolute;
  bottom: 0;
}

.flexJustifyEnd {
  justify-content: end;
}

.table-loading-placeholder {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  width: 99%;
}

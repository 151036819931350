.container {
  display: flex;
  flex-direction: row;
  min-height: calc(100vh - 90px);
}

.menuLink {
  display: block;
  padding: 18px;
  text-align: center;

  div {
    display: flex;
    flex-direction: row;
    align-items: center;

    img {
      width: 20px;
      height: 20px;
    }

    span {
      text-align: left;
      font-weight: 600;
      font-size: 13px;
      line-height: 1.5em;
      color: #212835;
      margin-left: 9px;
    }
  }

}

.menu {
  width: 110px;
}
